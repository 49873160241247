import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './component/layout/layout/layout.component';
import { TermsGuard } from './guard/terms.guard';
 


const routes: Routes = [
  { path: '', loadChildren:() => import('./component/auth/auth.module').then(m => (m.AuthModule)) },
  {path:'dashboard' ,component:LayoutComponent , canActivate:[TermsGuard] ,
    children:[
      { path: '', loadChildren:() => import('./component/dashboard/dashboard.module').then(m => (m.DashboardModule))}
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
