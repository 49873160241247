import { createReducer, on } from '@ngrx/store';
import * as PipelineActions from './pipeline.actions';

export interface PipelineState {
  data: any;
  loading: boolean;
  error: any;
}

export const initialState: PipelineState = {
  data: null,
  loading: false,
  error: null,
};

export const pipelineReducer = createReducer(
  initialState,
  on(PipelineActions.loadPipelineData, (state) => ({
    ...state,
    loading: true,
    error: null,
  })),
  on(PipelineActions.loadPipelineDataSuccess, (state, { data }) => ({
    ...state,
    loading: false,
    data: data,
  })),
  on(PipelineActions.loadPipelineDataFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error: error,
  }))
);
