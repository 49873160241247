import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutComponent } from './layout/layout.component';
import { TopbarComponent } from './topbar/topbar.component';
import { RouterModule } from '@angular/router';
import { MaterialUiModule } from '../../material-ui.modules';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FormsModule } from '@angular/forms';
import { DashboardModule } from "../dashboard/dashboard.module";



@NgModule({
  declarations: [LayoutComponent ,TopbarComponent ,SidebarComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    MaterialUiModule,
    DashboardModule
],
  exports:[LayoutComponent]
})
export class LayoutModule { }
