import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = 'Lender Express';

  constructor(private msalService: MsalService) {}

  ngOnInit() {

    this.msalService.handleRedirectObservable().subscribe({
      next: (authResult) => {
        if (authResult) {
          sessionStorage.setItem('aadToken', authResult.idToken);
        }
      },
      error: (error) => {
        console.error('AppComponent Redirect Error:', error);
      }
    });
  }
}
