<div class="sidebar-container d-flex flex-column">
        <div class="">
            <div class="p-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
               
                <p class="header-title-sort ml-4 text-center"  title="Lender Express"> LE </p>
            </div>
            <div 
            class="d-flex justify-content-center sidebar-item selectorOne" 
            title="Pipeline"
            [class.selected]="isSelected('pipeline')" 
            (click)="selectItem('pipeline')">
            <img src="assets/icons/note-icon.svg" alt="pipeline" title="Pipeline"  />
           
        </div>
    
        <div 
         *ngIf="checkIsAdmin"
            class="d-flex sidebar-item selectorTwo justify-content-center" 
            [class.selected]="isSelected('manageUsers')" 
            title="Manage Users"
            (click)="selectItem('manageUsers')">
            <img src="assets/icons/multi-user-icon.svg" alt="manageUsers"   />
            
        </div>
           
        </div>
        <div class="mt-auto  text-white bottom-icon">
            <div class="d-flex ">
            </div>
            <div class="d-flex ">
            </div>
            
            <div class="d-flex sidebar-item justify-content-center"  title="Support">
                <img src="assets/icons/support-icon.svg" alt="multiuser"  /> 
            </div>
            
            <div class="d-flex sidebar-item justify-content-center" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <p class="header-title ml-4 text-center"  title="Lender Express"><i class="bi bi-chevron-double-right"></i></p>
            </div>
            
           
        </div>
      

</div>


  
  <div class="offcanvas offcanvas-start sidebar-offcanvas" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
   
    <div class="sidebar-container d-flex flex-column">   
      <div class="">
          <div class="p-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
             <!-- <p  class="header-title ml-4 text-center"> <i class="bi bi-chevron-double-left"></i></p> -->
            <p class="header-title ml-4 text-center">Lender Express </p>
          </div>
          <div 
          class="d-flex sidebar-item selectorOne" 
          [class.selected]="isSelected('pipeline')" 
          (click)="selectItem('pipeline')">
          <img src="assets/icons/note-icon.svg" alt="pipeline" class="mx-2" />
          <p class="mt-3 mx-2  pipeline-text">Pipeline</p>
      </div>
  
      <div 
       *ngIf="checkIsAdmin"
          class="d-flex sidebar-item selectorTwo" 
          [class.selected]="isSelected('manageUsers')" 
          (click)="selectItem('manageUsers')">
          <img src="assets/icons/multi-user-icon.svg" alt="manageUsers" class="mx-2" />
          <p class="mt-3 mx-2  pipeline-text">Manage Users</p>
      </div>
         
      </div>
      <div class="mt-auto  text-white">
          <div class="d-flex ">
          </div>
          <div class="d-flex ">
          </div>
          
          <div class="d-flex sidebar-item">
              <img src="assets/icons/support-icon.svg" alt="multiuser" class="mx-2 " /> <p class="mt-3 mx-2  pipeline-text">Support</p>
          </div>
          
          <div class="d-flex flex-row-reverse p-4">
            <p  class="header-title" data-bs-dismiss="offcanvas"> <i class="bi bi-chevron-double-left"></i></p>
          </div>
          
         
      </div>
  
</div>

  </div>