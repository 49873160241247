<p  class="popup-header-label">
  Choose a City for {{data[0]?.city}}, {{data[0]?.stateCode}} ({{data[0]?.zipCode}}) </p>
<div  >

 
    <div class="error-txt">
        <p>The ZIP code {{data[0]?.zipCode}} is associated with multiple cities. Please choose a city below. </p>
        
    </div>

    <div class="m-3 city-list">
        <mat-radio-group aria-label="Select a city" [(ngModel)]="selectedCity" class="city-option-radio-text ">
         
            <mat-radio-button 
              *ngFor="let item of data" 
              [value]="item.city">
              
                      {{ item.city }} , {{item.stateCode}} ({{item.county}})
                    
                  </mat-radio-button>
                  
           
          </mat-radio-group>
    </div>
   
  <div align="end" class="popup-footer">
    
    <button mat-button class="accept-button " (click)="onClose()" style="color:#FFFFFF;" > SUBMIT</button>
    
  </div>

</div>








