import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-multi-option-city-popup',

  templateUrl: './multi-option-city-popup.component.html',
  styleUrl: './multi-option-city-popup.component.scss'
})
export class MultiOptionCityPopupComponent {


  selectedCity:any;

  constructor(public dialogRef: MatDialogRef<MultiOptionCityPopupComponent> , @Inject(MAT_DIALOG_DATA) public data: any){
    this.selectedCity = data[0].city 
  }

  onClose(): void {
    this.dialogRef.close(this.selectedCity);
  }

}
