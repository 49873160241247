import { Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LeadSuccessPopupComponent } from '../lead-success-popup/lead-success-popup.component';
import { DataServiceService } from '../../common/data-service.service';
import { LeadFailurePopupComponent } from '../lead-failure-popup/lead-failure-popup.component';
import { PipelineComponent } from '../../component/dashboard/pipeline/pipeline.component';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AppState } from '../../states/app.state';
import { Store } from '@ngrx/store';
import { MultiOptionCityPopupComponent } from '../multi-option-city-popup/multi-option-city-popup.component';
import { MatSelect } from '@angular/material/select';


@Component({
  selector: 'app-new-lead-popup',
  templateUrl: './new-lead-popup.component.html',
  styleUrls: ['./new-lead-popup.component.scss']
})
export class NewLeadPopupComponent   {
  form: FormGroup;
  isChecked: boolean = false;
  isFocused1 = false;
  isFocused2 = false;
  isFocused3 = false;
  isFocused4 = false;
  isFocused5 = false;

 // thirdSectionHeight: string = '7.5rem';

  invalidZipCode:boolean = false;
 
  states = [
    {
        "id": 1,
        "name": "Alabama",
        "abbreviation": "AL"
    },
    {
        "id": 2,
        "name": "Alaska",
        "abbreviation": "AK"
    },
    {
        "id": 3,
        "name": "Arizona",
        "abbreviation": "AZ"
    },
    {
        "id": 4,
        "name": "Arkansas",
        "abbreviation": "AR"
    },
    {
        "id": 5,
        "name": "California",
        "abbreviation": "CA"
    },
    {
        "id": 6,
        "name": "Colorado",
        "abbreviation": "CO"
    },
    {
        "id": 7,
        "name": "Connecticut",
        "abbreviation": "CT"
    },
    {
        "id": 8,
        "name": "Delaware",
        "abbreviation": "DE"
    },
    {
        "id": 9,
        "name": "District of Columbia",
        "abbreviation": "DC"
    },
    {
        "id": 10,
        "name": "Florida",
        "abbreviation": "FL"
    },
    {
        "id": 11,
        "name": "Georgia",
        "abbreviation": "GA"
    },
    {
        "id": 12,
        "name": "Guam",
        "abbreviation": "GU"
    },
    {
        "id": 13,
        "name": "Hawaii",
        "abbreviation": "HI"
    },
    {
        "id": 14,
        "name": "Idaho",
        "abbreviation": "ID"
    },
    {
        "id": 15,
        "name": "Illinois",
        "abbreviation": "IL"
    },
    {
        "id": 16,
        "name": "Indiana",
        "abbreviation": "IN"
    },
    {
        "id": 17,
        "name": "Iowa",
        "abbreviation": "IA"
    },
    {
        "id": 18,
        "name": "Kansas",
        "abbreviation": "KS"
    },
    {
        "id": 19,
        "name": "Kentucky",
        "abbreviation": "KY"
    },
    {
        "id": 20,
        "name": "Louisiana",
        "abbreviation": "LA"
    },
    {
        "id": 21,
        "name": "Maine",
        "abbreviation": "ME"
    },
    {
        "id": 22,
        "name": "Maryland",
        "abbreviation": "MD"
    },
    {
        "id": 23,
        "name": "Massachusetts",
        "abbreviation": "MA"
    },
    {
        "id": 24,
        "name": "Michigan",
        "abbreviation": "MI"
    },
    {
        "id": 25,
        "name": "Minnesota",
        "abbreviation": "MN"
    },
    {
        "id": 26,
        "name": "Mississippi",
        "abbreviation": "MS"
    },
    {
        "id": 27,
        "name": "Missouri",
        "abbreviation": "MO"
    },
    {
        "id": 28,
        "name": "Montana",
        "abbreviation": "MT"
    },
    {
        "id": 29,
        "name": "Nebraska",
        "abbreviation": "NE"
    },
    {
        "id": 30,
        "name": "Nevada",
        "abbreviation": "NV"
    },
    {
        "id": 31,
        "name": "New Hampshire",
        "abbreviation": "NH"
    },
    {
        "id": 32,
        "name": "New Jersey",
        "abbreviation": "NJ"
    },
    {
        "id": 33,
        "name": "New Mexico",
        "abbreviation": "NM"
    },
    {
        "id": 34,
        "name": "New York",
        "abbreviation": "NY"
    },
    {
        "id": 35,
        "name": "North Carolina",
        "abbreviation": "NC"
    },
    {
        "id": 36,
        "name": "North Dakota",
        "abbreviation": "ND"
    },
    {
        "id": 37,
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
    },
    {
        "id": 38,
        "name": "Ohio",
        "abbreviation": "OH"
    },
    {
        "id": 39,
        "name": "Oklahoma",
        "abbreviation": "OK"
    },
    {
        "id": 40,
        "name": "Oregon",
        "abbreviation": "OR"
    },
    {
        "id": 41,
        "name": "Pennsylvania",
        "abbreviation": "PA"
    },
    {
        "id": 42,
        "name": "Puerto Rico",
        "abbreviation": "PR"
    },
    {
        "id": 43,
        "name": "Rhode Island",
        "abbreviation": "RI"
    },
    {
        "id": 44,
        "name": "South Carolina",
        "abbreviation": "SC"
    },
    {
        "id": 45,
        "name": "South Dakota",
        "abbreviation": "SD"
    },
    {
        "id": 46,
        "name": "Tennessee",
        "abbreviation": "TN"
    },
    {
        "id": 47,
        "name": "Texas",
        "abbreviation": "TX"
    },
    {
        "id": 48,
        "name": "Trust Territories",
        "abbreviation": "TT"
    },
    {
        "id": 49,
        "name": "Utah",
        "abbreviation": "UT"
    },
    {
        "id": 50,
        "name": "Vermont",
        "abbreviation": "VT"
    },
    {
        "id": 51,
        "name": "Virgin Islands",
        "abbreviation": "VI"
    },
    {
        "id": 52,
        "name": "Virginia",
        "abbreviation": "VA"
    },
    {
        "id": 53,
        "name": "Washington",
        "abbreviation": "WA"
    },
    {
        "id": 54,
        "name": "West Virginia",
        "abbreviation": "WV"
    },
    {
        "id": 55,
        "name": "Wisconsin",
        "abbreviation": "WI"
    },
    {
        "id": 56,
        "name": "Wyoming",
        "abbreviation": "WY"
    }
]

loanOfficers:any;
 

@ViewChild('loanOfficeInput') loanOfficeInput!: ElementRef<HTMLInputElement>;

  constructor(
    public dialog: MatDialog ,
        public dataServiceService:DataServiceService,
      private renderer:Renderer2,public store: Store<AppState>,
    public dialogRef: MatDialogRef<NewLeadPopupComponent>,
    private router: Router,
    private fb: FormBuilder ,
    private loader: NgxUiLoaderService
   
  ) {
    
    this.form = this.fb.group({
      firstName: ['', [Validators.required , Validators.minLength(1)]],
      lastName: ['', [Validators.required , Validators.minLength(1)]],
      phoneNumber: ['', [,Validators.required,Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/) ,Validators.minLength(10)]], // Assuming a 10-digit phone number
      email: ['', [Validators.required, Validators.email , this.emailWithTLDValidator() ]],
      subjectPropertyCity: ['', Validators.required],
      state: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(6)]], // Assuming a 2-letter state code
      zipCode: ['', [Validators.required, Validators.pattern('^[0-9]{5}$')]], // Assuming a 5-digit zip code
      loanOfficer: ['', Validators.required],
      loanPurpose: ['', Validators.required],
      loanAmount: ['', [Validators.required ,  Validators.min(1)]],  
      monthlyIncome: ['', [ Validators.min(1)]], 
      monthlyDebt: ['', [ Validators.min(1)]], 
      existingPropertyValue: ['',[Validators.min(1)] ], 
      purchasePrice: ['', [Validators.min(1)]],
      isChecked: [false, Validators.requiredTrue],

    });


    this.loadFormValues();

    // Subscribe to valueChanges to update local storage
    this.form.valueChanges.subscribe(values => {
      localStorage.setItem('leadFormValues', JSON.stringify(values));
      
    });

    
    this.dataServiceService.getLoanOfficers().subscribe(
      (res:any) => {
        this.loanOfficers = res.details.values
        
      },
       (error) => {
        
       }

    )

    
}

ngOnInit() {
  this.phoneValidate();

  this.loanOfficeInput.nativeElement.focus();
}




 updateThirdSectionHeight(loanPurpose: string): void {
    if (loanPurpose === 'Purchase') {
     // this.thirdSectionHeight = '12.5rem';
    } else if (loanPurpose === 'Refinance') {
     // this.thirdSectionHeight = '12.5rem';
    }
  }


  onLoanPurposeChange(value: string): void {
    if (value === 'Refinance') {
       // this.thirdSectionHeight = '12.5rem';
        this.form.get('existingPropertyValue')?.setValidators([Validators.required ,Validators.min(1)]);
        this.form.get('purchasePrice')?.clearValidators();
        this.form.get('purchasePrice')?.setValue(null);
        
    } else if (value === 'Purchase') {
     //   this.thirdSectionHeight = '12.5rem';
        this.form.get('purchasePrice')?.setValidators([Validators.required ,Validators.min(1)]);
        this.form.get('existingPropertyValue')?.clearValidators();
        this.form.get('existingPropertyValue')?.setValue(null);
    } else {
       // this.thirdSectionHeight = '7.5rem';
        this.form.get('existingPropertyValue')?.clearValidators();
        this.form.get('purchasePrice')?.clearValidators();
        this.form.get('purchasePrice')?.setValue(null);
        this.form.get('existingPropertyValue')?.setValue(null);
    }
    this.form.get('existingPropertyValue')?.updateValueAndValidity();
    this.form.get('purchasePrice')?.updateValueAndValidity();
}


loadFormValues() {
  const storedValues = localStorage.getItem('leadFormValues');
  if (storedValues) {
    const values = JSON.parse(storedValues);

    this.updateThirdSectionHeight(values.loanPurpose);

    this.form.patchValue(values);

    this.onLoanPurposeChange(values.loanPurpose)
  }
}


  onAccept(): void {

   
    if (this.form.valid) {
      const formValue = this.form.value;

      const payload = {
        msaBuilderCode: "string",  
        loanDepotOfficerId: formValue.loanOfficer,  
        borrower: {
          firstName: formValue.firstName,
          lastName: formValue.lastName,
          email: formValue.email,
          phoneNumber: this.normalizePhoneNumber(formValue.phoneNumber),
          city: formValue.subjectPropertyCity,  
          state: formValue.state,
          zipCode: Number(formValue.zipCode)  
        },
        loan: {
          reason: formValue.loanPurpose, 
          amount: this.removeCommas(formValue.loanAmount), 
          existingPropertyValue:formValue.existingPropertyValue == ''?0:this.removeCommas(formValue.existingPropertyValue),
          purchasePrice:formValue.purchasePrice == ''?0:this.removeCommas(formValue.purchasePrice)
        },
        income: {
          monthlyIncome: this.removeCommas(formValue.monthlyIncome),  
          monthlyDebit: this.removeCommas(formValue.monthlyDebt)  
        },
        IPAddress:"192.168.1.4"

      };
  
      this.addNewLoad(payload);
       
      
    } else {
      this.form.markAllAsTouched(); 
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  openNewLeadSuccessPopup() {
    const dialogRef = this.dialog.open(LeadSuccessPopupComponent, {
      width: '960px',
    });
  }


  openNewLeadFailurePopup(status:any) {
    const dialogRef = this.dialog.open(LeadFailurePopupComponent, {
      width: '960px',
      data: status,
    });
  }





  addNewLoad(payload:any): void {
    this.loader.start();
    this.dataServiceService.addNewLoad(payload).subscribe(
      (response) => {
       
        if(response.details.status === "Success"){
          localStorage.removeItem('leadFormValues');
          
         // this.dataServiceService.getPipelineViewData()
          this.openNewLeadSuccessPopup();
          this.dialogRef.close(true);

          let selectedSidebar =  localStorage.getItem('selectedSidebarItem')

          if(selectedSidebar === 'manageUsers' ){

            localStorage.setItem('selectedSidebarItem' , 'pipeline' )
               
            setTimeout(() => { window.location.reload() },1000 )
          }


          this.loader.stop();
        }else{
            this.loader.stop();
            this.dialogRef.close(false);
          this.openNewLeadFailurePopup(response.details.statusMessage);
        }
        
      },
      (error) => {
        console.error('Error adding new load:', error);
        this.loader.stop();
        this.dialogRef.close(false);
        this.openNewLeadFailurePopup('');
      
      }
    );
  }



  phoneValidate(){
    const phoneControl:AbstractControl = this.form.controls['phoneNumber'];
    
    phoneControl.valueChanges.subscribe(data => {
      
      /**the most of code from @Günter Zöchbauer's answer.*/

      /**we remove from input but: 
         @preInputValue still keep the previous value because of not setting.
      */
      let preInputValue:string = this.form.value.phoneNumber;
      var lastChar:string = preInputValue.substr(preInputValue.length - 1);
      // remove all mask characters (keep only numeric)
      var newVal = data.replace(/\D/g, '');

      let start = this.renderer.selectRootElement('#tel').selectionStart;
      let end = this.renderer.selectRootElement('#tel').selectionEnd;
  
      if (data.length < preInputValue.length) {
      
  
      if(preInputValue.length < start){
        if(lastChar == ')'){
          newVal = newVal.substr(0,newVal.length-1); 
        }
      }
      //if no number then flush
      if (newVal.length == 0) {
        newVal = '';
      } 
      else if (newVal.length <= 3) {
        /**when removing, we change pattern match.
        "otherwise deleting of non-numeric characters is not recognized"*/
        newVal = newVal.replace(/^(\d{0,3})/, '($1');
      } else if (newVal.length <= 6) {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
      } else {
        newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
      }
     
      this.form.controls['phoneNumber'].setValue(newVal,{emitEvent: false});
       //keep cursor the normal position after setting the input above.
      this.renderer.selectRootElement('#tel').setSelectionRange(start,end);
    //when typed value in input
    } else{
    
      var removedD = data.charAt(start);
    // don't show braces for empty value
    if (newVal.length == 0) {
      newVal = '';
    } 
    // don't show braces for empty groups at the end
    else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
    }
    //check typing whether in middle or not
    //in the following case, you are typing in the middle
    if(preInputValue.length >= start){
      //change cursor according to special chars.
      console.log(start+removedD);
      if(removedD == '('){
        start = start +1;
        end = end +1;
      }
      if(removedD == ')'){
        start = start +2; // +2 so there is also space char after ')'.
        end = end +2;
      }
      if(removedD == '-'){
        start = start +1;
        end = end +1;
      }
      if(removedD == " "){
          start = start +1;
          end = end +1;
        }
      this.form.controls['phoneNumber'].setValue(newVal,{emitEvent: false});
      this.renderer.selectRootElement('#tel').setSelectionRange(start,end);
    } else{
        this.form.controls['phoneNumber'].setValue(newVal,{emitEvent: false});
        this.renderer.selectRootElement('#tel').setSelectionRange(start+2,end+2); // +2 because of wanting standard typing
    }
  }

  
  });
 }


 emailWithTLDValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    // Regex pattern to match email with TLD
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    // Check if the value matches the pattern
    const valid = emailPattern.test(value);
    return valid ? null : { email: true }; // Return error if invalid
  };
}

removeCommas(value:any) {
  if (typeof value === 'string') {
    // Remove commas and convert to number, parse as float and then round to integer
    return Math.round(Number(value.replace(/,/g, '')));
  }
  return typeof value === 'number' ? Math.round(value) : NaN;
}


 normalizePhoneNumber(phone: string): string {
  return phone.replace(/\D/g, '');
}


onZipCodeChange() {
  this.invalidZipCode = false;
  const zipCodeControl = this.form.get('zipCode');
  let zipCode = zipCodeControl?.value;

    if (zipCode && zipCode.length === 5) {
     
      this.dataServiceService.getCityState(zipCode).subscribe(
        (response:any) => {
          if (response && response.details.totalCount > 0 ) {
            const city = response?.details?.zipCode[0].city
            const state =  response?.details?.zipCode[0].stateCode

            // Update city and state in the form

            if( response?.details?.zipCode?.length  == 1 ){

              this.form.patchValue({
                state: state,
                subjectPropertyCity:city
              });
             
            }else{
              this.form.patchValue({
                state: state,
              });
              this.openMultiSelectCityDialog(response?.details?.zipCode)
            }

            this.invalidZipCode = false;
          }else{
            this.invalidZipCode = true; 

            this.form.patchValue({
              subjectPropertyCity:null,
              state: null,
            });
            zipCodeControl?.markAsTouched();
          }
        },
        (error:any) => {
          console.error('Error fetching city/state', error); 
        }
      );
    }


}


openMultiSelectCityDialog(data:any){
  const dialogRef = this.dialog.open(MultiOptionCityPopupComponent, {
    width: '960px',
    data:data
  });

  dialogRef.afterClosed().subscribe(result => {
    if(result){
       

      this.form.patchValue({
       subjectPropertyCity:result
      });
    }else{
      this.form.patchValue({
        subjectPropertyCity:data[0]?.city
       });
    }
  });
}



}


