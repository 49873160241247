import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { endUrls } from '../../../environments/end-urls';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = localStorage.getItem('baseUrl');

  constructor(private http: HttpClient) {}

  sendAuthToken(token: string): Observable<any> {
  
    return this.http.post(this.apiUrl + endUrls.authentication, {});
  }


  setTermsAgreementConsent(payload:any): Observable<any> {
  
    const token = localStorage.getItem('tokenValue');

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    });
  

    return this.http.post(this.apiUrl + endUrls.termsAgreementConsent,payload, { headers });
  }


  userInfo:any;

  set setUserInfo(data:any){
      this.userInfo = data;
  }

 get getUserInfo(){
  return  this.userInfo ;
}



}
