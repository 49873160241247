import { Component, Inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';



@Component({
  selector: 'app-lead-failure-popup',
  
  templateUrl: './lead-failure-popup.component.html',
  styleUrls: ['./lead-failure-popup.component.scss']
})
export class LeadFailurePopupComponent {

  errorStatus:any;

  constructor(public dialogRef: MatDialogRef<LeadFailurePopupComponent> ,@Inject(MAT_DIALOG_DATA) public data: any){
    this.errorStatus = data
  }

  onClose(): void {
    this.dialogRef.close();
  }

}
