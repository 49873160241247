import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-authorization-failed-popup',

  templateUrl: './authorization-failed-popup.component.html',
  styleUrl: './authorization-failed-popup.component.scss'
})
export class AuthorizationFailedPopupComponent {

  constructor(public dialogRef: MatDialogRef<AuthorizationFailedPopupComponent>){}

  
onClose(): void {
  this.dialogRef.close();
}

}
