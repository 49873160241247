import { Directive, OnDestroy, OnInit, ElementRef } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { NgControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Directive({
  selector: '[inputWithComma]',
  providers: [DecimalPipe]
})
export class NumberCommaDirective implements OnInit, OnDestroy {
  private subscription: Subscription | null = null;

  constructor(
    private ngControl: NgControl,
    private decimal: DecimalPipe,
    private el: ElementRef
  ) {}

  ngOnInit() {
    const control = this.ngControl.control;

    if (control) {
      // Format the initial value if present
      if (control.value) {
        control.setValue(this.formatValue(control.value), { emitEvent: false });
      }

      this.subscription = control.valueChanges.pipe(
        debounceTime(300), // Reduce formatting frequency
        map(value => this.formatValue(value))
      ).subscribe(v => {
        const inputElement = this.el.nativeElement;

        // Store current selection
        const start = inputElement.selectionStart;

        // Update the control value if formatted value differs
        if (v !== control.value) {
          control.setValue(v, { emitEvent: false });
        }

        // Calculate the new cursor position and set it
        setTimeout(() => {
          const newPosition = this.calculateCursorPosition(v, start);
          this.setCursorPosition(inputElement, newPosition);
        }, 0);
      });
    }
  }

  private formatValue(value: any): string {
    if (value === null || value === undefined || value === '') return '';

    const parsedValue = value.toString().replace(/,/g, ''); // Remove commas for parsing
    const numericValue = parseFloat(parsedValue);

    // Check if the value is a valid number
    if (isNaN(numericValue)) return '';

    // Format using DecimalPipe for two decimal places
    const formattedValue = this.decimal.transform(numericValue, '1.2-2') || '';

    // Ensure there's no leading comma by handling empty integer part
    return formattedValue.replace(/^0+/, '') || '0.00';
  }

  private calculateCursorPosition(formattedValue: string, cursorPosition: number): number {
    const originalValue = this.ngControl.control?.value?.toString().replace(/,/g, '') || '';
    const originalLength = originalValue.length;

    // If the cursor was at the end of the original input, set to the end of the formatted value
    if (cursorPosition >= originalLength) {
      return formattedValue.length;
    }

    // Count commas in the original and formatted values
    const originalCommas = (originalValue.slice(0, cursorPosition).match(/,/g) || []).length;
    const formattedCommas = (formattedValue.slice(0, cursorPosition).match(/,/g) || []).length;

    // Adjust cursor position based on the difference in commas
    return cursorPosition + (formattedCommas - originalCommas);
  }

  private setCursorPosition(input: HTMLInputElement, position: number) {
    if (input) {
      input.setSelectionRange(position, position);
      input.focus(); // Ensure the input remains focused
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
