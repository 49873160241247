import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  constructor(private http: HttpClient) {}

  fetchData(): Promise<any> {
    return this.http.get<any>('assets/env-settings.json').toPromise();
  }

  initializeUrls() {
    const config = JSON.parse(localStorage.getItem('config') || '');

    if (config) {
      localStorage.setItem('apim-key', config.ocpApimSubscriptionKey);
      localStorage.setItem('b2c-client-id', config.clientId);
      localStorage.setItem('aad-client-id', config.aadClientId);
      localStorage.setItem('redirectUrl', config.redirectUrl);
      localStorage.setItem('baseUrlB2C', config.baseUrlB2C);
      localStorage.setItem('baseUrl', config.baseUrl);
      localStorage.setItem('aadUri', config.apiConfigUri);
    }
  }
}
