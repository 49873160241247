import { Injectable } from '@angular/core';
import { TermsServicePopupComponent } from './terms-service-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthorizationFailedPopupComponent } from '../authorization-failed-popup/authorization-failed-popup.component';

@Injectable({
  providedIn: 'root'
})
export class TermsServicePopupService {
  constructor(private dialog: MatDialog, private router: Router) {}

  openTermsDialog(): Promise<boolean> {
    const dialogRef =  this.dialog.open(TermsServicePopupComponent, {
      disableClose: true ,
      width: '1200px',
      // height:'268px'
    });

    return new Promise<boolean>((resolve) => {
      dialogRef.componentInstance.termsAccepted.subscribe((accepted: boolean) => {
        resolve(true);
      });
      dialogRef.afterClosed().subscribe(() => {
       
        resolve(true);
      });
    });
  }

  authorizationFailedDialog(){
 
    const dialogRef =  this.dialog.open(AuthorizationFailedPopupComponent, {
      width: '960px',
    });

  }
}
