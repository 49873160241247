import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from './component/auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PopupsModule } from './popups/popups.module';
import { LayoutModule } from './component/layout/layout.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { DashboardModule } from './component/dashboard/dashboard.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppState } from './states/app.state';
import { StoreModule } from '@ngrx/store';
import { pipelineReducer } from './states/pipeline/pipeline.reducer';
import { AuthInterceptor } from './common/interceptors/auth.interceptor';
import { searchTextReducer } from './states/searchText/search.reducer';
import {
  MSAL_INSTANCE,
  MSAL_GUARD_CONFIG,
  MSAL_INTERCEPTOR_CONFIG,
  MsalService,
  MsalGuard
} from '@azure/msal-angular';
import {
  MSALInstanceFactory,
  MSALGuardConfigFactory,
  MSALInterceptorConfigFactory
} from './msal.factory';
import { AppService } from './app.service';

export function initializeApp(appService: AppService) {
  return () =>
    appService.fetchData().then((data) => {
      localStorage.setItem('config', JSON.stringify(data));
      appService.initializeUrls()
    });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot<AppState>({
      pipeline: pipelineReducer,
      searchText: searchTextReducer
    }),
    AuthModule,
    PopupsModule,
    DashboardModule,
    LayoutModule,
    NgxUiLoaderModule
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      multi: true,
      deps: [AppService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule {}
