import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-lead-success-popup',

  templateUrl: './lead-success-popup.component.html',
  styleUrls: ['./lead-success-popup.component.scss']
})
export class LeadSuccessPopupComponent {


constructor(public dialogRef: MatDialogRef<LeadSuccessPopupComponent>){}

  onClose(): void {
    this.dialogRef.close();
  }


}
