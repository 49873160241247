<h2 mat-dialog-title class="popup-header-label mx-2">Create a New Lead</h2>
 
<div [formGroup]="form" class="lead-container">
  <div class="row first-section mt-2">
    <div class="col-md-6 typelabel mt-2 mb-md-3 mb-sm-3">
      <div class="mb-1"><p class="mx-2 label-txt org-type">Origination Type</p></div>
      <br>
      <div><p class="mx-2 label-txt retail">Retail</p></div>
    </div>
    <div class="col-md-6 mb-md-3 mb-sm-3 ">
      <mat-form-field appearance="outline" class="custom-login-input"  >
        <mat-label>loanDepot Lender Express POC</mat-label>
        <mat-select formControlName="loanOfficer"  #loanOfficeInput id="loan-officer"  tabindex="1">
          <mat-option *ngFor="let officer of loanOfficers" [value]="officer.value">
            {{ officer.text }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('loanOfficer')?.touched && form.get('loanOfficer')?.hasError('required')">
           Please select the loan officer
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="sec-section">
    <p class="form-section-text">Borrower Information</p>
   
    <div class="row ">
      <div class="col-md-6 mt-4  ">
        <mat-form-field appearance="outline" class="custom-login-input mt-3">
          <mat-label class="hint-text">First Name</mat-label>
          <input matInput formControlName="firstName" autofocus class="input-text" appTitleCase>
          <mat-error *ngIf="form.get('firstName')?.touched && form.get('firstName')?.hasError('required')">
            First Name is required.
          </mat-error>
          <!-- <mat-error *ngIf="!form.get('firstName')?.hasError('required') && form.get('firstName')?.hasError('pattern')">
            First Name can only contain letters and spaces.
          </mat-error> -->
        </mat-form-field>
      </div>
      <div class="col-md-6 mt-4  ">
        <mat-form-field appearance="outline" class="custom-login-input mt-3">
          <mat-label class="hint-text">Last Name</mat-label>
          <input matInput formControlName="lastName" class="input-text" appTitleCase >
          <mat-error *ngIf="form.get('lastName')?.touched && form.get('lastName')?.hasError('required')">
            Last Name is required.
          </mat-error>
          <!-- <mat-error *ngIf="!form.get('lastName')?.hasError('required') && form.get('lastName')?.hasError('pattern')">
            Last Name can only contain letters and spaces.
          </mat-error> -->
        </mat-form-field>
      </div>
    </div>

    <div class="row ">
      <div class="col-md-6 mt-3 ">
        <mat-form-field appearance="outline" class="custom-login-input mt-4">
          <mat-label class="hint-text">Phone Number</mat-label>
          <input matInput formControlName="phoneNumber" id="tel"  class="input-text" >
          <mat-error *ngIf="form.get('phoneNumber')?.touched && form.get('phoneNumber')?.hasError('required')">
            Phone Number is required.
          </mat-error>
          <mat-error *ngIf="form.get('phoneNumber')?.touched && form.get('phoneNumber')?.hasError('pattern')">
            Phone Number must be 10 length.
          </mat-error>
        </mat-form-field>
      </div>
      <div  class="col-md-6 mt-3 ">
        <mat-form-field appearance="outline" class="custom-login-input mt-4">
          <mat-label class="hint-text">Email Address</mat-label>
          <input matInput formControlName="email" class="input-text">
          <mat-error *ngIf="form.get('email')?.touched && form.get('email')?.hasError('required')">
            Email Address is required.
          </mat-error>
          <mat-error *ngIf="!form.get('email')?.hasError('required') &&form.get('email')?.touched && form.get('email')?.hasError('email')">
            Invalid email format.
          </mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row mb-3 ">
      <div  class="col-md-6 mt-3 ">
        <mat-form-field appearance="outline" class="custom-login-input mt-4">
          <mat-label class="hint-text">Subject Property City</mat-label>
          <input matInput formControlName="subjectPropertyCity"  class="input-text">
          <!-- <mat-error *ngIf="form.get('subjectPropertyCity')?.touched && form.get('subjectPropertyCity')?.hasError('required')">
            Enter the subject property city 
          </mat-error> -->
        </mat-form-field>
      </div>
      <div  class="col-md-6 mt-3  d-flex">
        <div >
          <mat-form-field appearance="outline" class="custom-login-input state-code mt-4" >
          
            <mat-label >State</mat-label>

        
            <mat-select formControlName="state" >
              <mat-option *ngFor="let state of states" [value]="state.abbreviation">
                {{ state.abbreviation }}
              </mat-option>
            </mat-select>

            <mat-error *ngIf="form.get('state')?.touched && form.get('state')?.hasError('required')">
              select state
            </mat-error>
            
          </mat-form-field>
        </div>
        <div>
          <mat-form-field appearance="outline" class="custom-login-input mt-4 zip-code">
            <mat-label class="hint-text">Zip Code</mat-label>
            <input matInput formControlName="zipCode" class="input-text"  (input)="onZipCodeChange()">
            <mat-error *ngIf="form.get('zipCode')?.touched && form.get('zipCode')?.hasError('required')">
              Zip Code is required.
            </mat-error>
            <mat-error *ngIf="form.get('zipCode')?.touched && form.get('zipCode')?.hasError('pattern')">
              Must be a 5-digit number.
            </mat-error>

            <p class="invalidZipError mat-mdc-form-field-error mat-mdc-form-field-subscript-wrapper" *ngIf="invalidZipCode && !form.get('zipCode')?.hasError('required') && !form.get('zipCode')?.hasError('pattern')">
              Invalid zip code
            </p>

          </mat-form-field>
        </div>
      </div>
    </div>
   
  </div>

  <div class="third-section  " >
    <p class="form-section-text">Loan Information</p>
    <div class="row mb-4 mt-4">
      <div class="col-md-6 mt-3 mb-3">
        <mat-form-field appearance="outline" class="custom-login-input ">
          <mat-label>Loan Purpose</mat-label>
          <mat-select formControlName="loanPurpose"  placeholder="Select" (selectionChange)="onLoanPurposeChange($event.value)">
            <mat-option value="Purchase">Home Purchase</mat-option>
            <mat-option value="Refinance">Home Refinance</mat-option>
           
          </mat-select>

          <mat-error *ngIf="form.get('loanPurpose')?.touched && form.get('loanPurpose')?.hasError('required')">
            Please select the loan purpose
         </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6 mt-3 ">
        <mat-form-field appearance="outline" class="custom-login-input ">
          <mat-label class="hint-text">Loan Amount</mat-label>
          
          <input   matInput formControlName="loanAmount" class="input-text"   
          (focus)="isFocused1 = true" inputWithComma
          >
          <p *ngIf="isFocused1 || form.get('loanAmount')?.value" matPrefix class="currency-format"> $ </p>
          <mat-error *ngIf="form.get('loanAmount')?.touched && form.get('loanAmount')?.hasError('required')">
            Loan Amount is required.
          </mat-error>

          <mat-error *ngIf="form.get('loanAmount')?.touched && form.get('loanAmount')?.hasError('min')">
            Must be greater than 0.
          </mat-error>
         
        </mat-form-field>
      </div>
    </div>  
    
    <div  *ngIf="form.get('loanPurpose')?.value === 'Refinance'" class=" row mt-4 mb-4 " >
      <mat-form-field appearance="outline" class=" purchase-refinance-input custom-login-input mb-4 " >
        <mat-label class="hint-text">Existing Property Value</mat-label>
        <input matInput formControlName="existingPropertyValue" class="input-text" (focus)="isFocused2 = true" inputWithComma>
        <p *ngIf="isFocused2 || form.get('existingPropertyValue')?.value" matPrefix class="currency-format"> $ </p>
        <mat-error class="mb-3" *ngIf="form.get('existingPropertyValue')?.touched && form.get('existingPropertyValue')?.hasError('required')">
          Existing Property Value is required.
        </mat-error>
        <mat-error class="mb-3" *ngIf="form.get('existingPropertyValue')?.touched && form.get('existingPropertyValue')?.hasError('pattern')">
          Existing Property Value must be a number.
        </mat-error>

        <mat-error *ngIf="form.get('existingPropertyValue')?.touched && form.get('existingPropertyValue')?.hasError('min')">
          Must be greater than 0.
        </mat-error>

      </mat-form-field>
    </div>

    <div *ngIf="form.get('loanPurpose')?.value === 'Purchase'" class=" row mt-4 mb-4 ">
      <mat-form-field appearance="outline"  class=" purchase-refinance-input custom-login-input mb-4  " >
          <mat-label class="hint-text">Purchase Price</mat-label>
          <input matInput formControlName="purchasePrice" class="input-text" (focus)="isFocused5 = true" inputWithComma>
          <p *ngIf="isFocused5 || form.get('purchasePrice')?.value" matPrefix class="currency-format"> $ </p>
          <mat-error  class="mb-3" *ngIf="form.get('purchasePrice')?.touched && form.get('purchasePrice')?.hasError('required')">
              Purchase Price is required.
          </mat-error>

          <mat-error *ngIf="form.get('purchasePrice')?.touched && form.get('purchasePrice')?.hasError('min')">
            Must be greater than 0.
          </mat-error>
      </mat-form-field>
  </div>
  </div>

  <div class="last-section">
    <p class="form-section-text">Income Information</p>
    <div class="row mb-4">
      <div class="col-md-6 mt-3">
        <mat-form-field appearance="outline" class="custom-login-input mt-3">
          <mat-label class="hint-text">Monthly Income</mat-label>
          <input matInput formControlName="monthlyIncome" class="input-text" (focus)="isFocused3 = true" inputWithComma>
          <p *ngIf="isFocused3 || form.get('monthlyIncome')?.value" matPrefix class="currency-format"> $ </p>

          <mat-error *ngIf="form.get('monthlyIncome')?.touched && form.get('monthlyIncome')?.hasError('required')">
            Monthly Income is required.
          </mat-error>
          <mat-error *ngIf="form.get('monthlyIncome')?.touched && form.get('monthlyIncome')?.hasError('pattern')">
            Monthly Income must be a number.
          </mat-error>

          <mat-error *ngIf="form.get('monthlyIncome')?.touched && form.get('monthlyIncome')?.hasError('min')">
            Must be greater than 0.
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col-md-6 mt-3">
        <mat-form-field appearance="outline" class="custom-login-input mt-3">
          <mat-label class="hint-text">Monthly Debt</mat-label>
          <input matInput formControlName="monthlyDebt" class="input-text" (focus)="isFocused4 = true" inputWithComma >
          <p *ngIf="isFocused4 || form.get('monthlyDebt')?.value" matPrefix class="currency-format"> $ </p>

          <mat-error *ngIf="form.get('monthlyDebt')?.touched && form.get('monthlyDebt')?.hasError('required')">
            Monthly Debt is required.
          </mat-error>
          <mat-error *ngIf="form.get('monthlyDebt')?.touched && form.get('monthlyDebt')?.hasError('pattern')">
            Monthly Debt must be a number.
          </mat-error>
          <mat-error *ngIf="form.get('monthlyDebt')?.touched && form.get('monthlyDebt')?.hasError('min')">
            Must be greater than 0.
          </mat-error>

        </mat-form-field>
      </div>
    </div>
  </div>

  <div align="end" class="popup-footer ">
    <div   style="display: flex; align-items: center; height: 0px; margin-top:2.2rem;">
      <mat-checkbox formControlName="isChecked"></mat-checkbox>
      <span class="body-text">
        I have confirmed consumer consent to submit the above information to loanDepot.
      </span>
    </div>
    
  <div class=" popup-footer-btn">
    <button mat-button class="close-btn m-2 text-light btn-font" (click)="onClose()">CLOSE</button>

    <button 
    mat-button 
    class="accept-button m-2 btn-font" 
    (click)="onAccept()" 
    [disabled]="!form.valid"
  [ngStyle]="{
      'background-color': !form.valid ? '#E6E5EA' : '#7A3FF6',
      'color': !form.valid ? '#9391A1' : '#fff'
    }">
    CREATE LEAD
  </button>

  </div>
    

    
  
  </div>
</div>



