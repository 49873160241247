import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NewLeadPopupComponent } from '../../../popups/new-lead-popup/new-lead-popup.component';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { DataServiceService } from '../../../common/data-service.service';
import { Location } from '@angular/common';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnChanges , OnDestroy {
  userData: any;
  @Input() showSearchBar = true;
  selectedValue: string = 'firstName';
  selectedValueLabel: string = 'First Name';
  searchTerm: string = '';
  @Output() resetSorting = new EventEmitter<void>();

  clientId: string = localStorage.getItem('b2c-client-id') || '';
  redirectUrl: string = localStorage.getItem('redirectUrl') || '';
  baseUrlB2C: string = localStorage.getItem('baseUrlB2C') || '';

  loginUrl: string = `${this.baseUrlB2C}${environment.userflow}&client_id=${this.clientId}&nonce=defaultNonce&redirect_uri=${this.redirectUrl}&scope=openid&response_type=id_token&prompt=login`;

  constructor(
    private location: Location,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    public authService: AuthService,
    public dataServiceService: DataServiceService
  ) {
    this.getUserData();
  }

  ngOnInit() {
    this.getUserData();
  }
  ngOnChanges(changes: SimpleChanges): void {}

  selectValue(value: string): void {
    switch (value) {
      case 'First Name':
        this.selectedValue = 'firstName';
        this.selectedValueLabel = 'First Name';
        this.searchTerm = '';
        break;
      case 'Last Name':
        this.selectedValue = 'lastName';
        this.selectedValueLabel = 'Last Name';
        this.searchTerm = '';
        break;
      case 'Phone':
        this.selectedValue = 'phone';
        this.selectedValueLabel = 'Phone';
        this.searchTerm = '';
        break;
      case 'Borrower Email':
        this.selectedValue = 'borrowerEmail';
        this.selectedValueLabel = 'Borrower Email';
        this.searchTerm = '';
        break;
      case 'Property City':
        this.selectedValue = 'PropertyCity';
        this.selectedValueLabel = 'Property City';
        this.searchTerm = '';
        break;
      default:
        this.selectedValue = '';
        break;
    }
  }

  getUserData() {
    const userInfo = localStorage.getItem('userInfo');
    this.userData = userInfo ? JSON.parse(userInfo) : '';
  }

  logout() {
    this.dataServiceService.getLogoutActivity().subscribe((res: any) => {
      console.log('LOGOUT ALL ACTIVITIES SUCCESSFULLY');
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = this.loginUrl;
    });
  }

  openNewLeadPopup() {
    const dialogRef = this.dialog.open(NewLeadPopupComponent, {
      width: '960px',
    
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        console.log('Form submitted successfully');

        this.clearSearch();
      } else {
        console.log('Form submission was canceled or failed');
      }
    });
  }

  performSearch(): void {
    let data = this.dataServiceService.getPipelineViewData(
      this.selectedValue,
      this.searchTerm
    );
    this.resetSorting.emit();
  }

  clearSearch() {
    this.searchTerm = '';
    let data = this.dataServiceService.getPipelineViewData();

    this.resetSorting.emit();
  }


  ngOnDestroy(): void {
     this.searchTerm = ''
  }
}
