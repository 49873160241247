import { Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { PipelineComponent } from '../../dashboard/pipeline/pipeline.component';
import { TopbarComponent } from '../topbar/topbar.component';
 

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})

export class LayoutComponent {

  checkIsAdmin:boolean= false;

  userInfo:any;

  currentComponent: any;

  isBurgerMenuOpen = false;
  selectedItem: string = 'pipeline';
  showSearchBar = true;


  isMobile: boolean = false; 
  private mediaQueryList!: MediaQueryList;


  @ViewChild(PipelineComponent) pipelineComponent!:PipelineComponent;

  @ViewChild(TopbarComponent) topbarComponent!:TopbarComponent;

  constructor(){
  
    this.userInfo = localStorage.getItem('userInfo')

    if(this.userInfo){
       
      this.userInfo = JSON.parse(this.userInfo)

        this.checkIsAdmin =  this.userInfo.isAdmin

    }

    let selectedSidebar =  localStorage.getItem('selectedSidebarItem')
     
    if(selectedSidebar){
     
      this.currentComponent = selectedSidebar;
      this.selectedItem = selectedSidebar;
      
      if(selectedSidebar ==='pipeline'){
       this.showSearchBar =  true;
      }else{
        this.showSearchBar =  false;
      }

    }else{
      this.currentComponent = 'pipeline';
      this.selectedItem = 'pipeline';
    }
   
  }



  ngOnInit(): void {
    
    this.mediaQueryList = window.matchMedia('(max-width: 430px)');
    this.isMobile = this.mediaQueryList.matches; 


  }

 
  isSelected(item: string): boolean {
      this.topbarComponent.searchTerm = ''
      this.topbarComponent.selectedValue = 'firstName';
      this.topbarComponent.selectedValueLabel = 'First Name';
    return this.selectedItem === item;
  }

  selectItem(item: string): void {
    this.isBurgerMenuOpen = false;
    this.currentComponent = item;
    this.selectedItem = item;
    this.topbarComponent.searchTerm = ''
    this.topbarComponent.selectedValue = 'firstName';
      this.topbarComponent.selectedValueLabel = 'First Name';

    localStorage.setItem('selectedSidebarItem' ,item)

    if(item === 'pipeline'){
      this.showSearchBar = true;
    }else{
      this.showSearchBar = false;
    }
  }
  

  toggleBurgerMenu() {
    this.isBurgerMenuOpen = !this.isBurgerMenuOpen;
  }


  openOffcanvas() {
    const offcanvasElement = document.getElementById('offcanvasExample');
  }


  resetSorting(){
    this.pipelineComponent.resetSorting()
  }
  


  toggleSelection(data:any){
 
  this.currentComponent =  data;

  this.topbarComponent.searchTerm = '';
  this.topbarComponent.selectedValue = 'firstName';
  this.topbarComponent.selectedValueLabel = 'First Name';

  if(data === 'pipeline'){
    this.showSearchBar = true;
  }else{
    this.showSearchBar = false;
  }
  
  }




}
