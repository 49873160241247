import { Component, EventEmitter, Output } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {

  selectedItem: string = 'pipeline';

  checkIsAdmin:boolean= false;

  userInfo:any;


  @Output() toggleMenuSelection: EventEmitter<any> = new EventEmitter<any>();

  constructor(private loader: NgxUiLoaderService) {
 
    this.userInfo = localStorage.getItem('userInfo')

    if(this.userInfo){
       
      this.userInfo = JSON.parse(this.userInfo)

        this.checkIsAdmin =  this.userInfo.isAdmin

    }

    

    let selectedSidebar =  localStorage.getItem('selectedSidebarItem')
     
    if(selectedSidebar){
      this.selectItem(selectedSidebar);
    }else{
      this.selectedItem = 'pipeline';
    }
   
  }

  selectItem(item: string) {
    this.selectedItem = item;
    this.loader.start()
    setTimeout(() => {

      this.toggleMenuSelection.emit(this.selectedItem)
      this.loader.stop()
    },1000)
    localStorage.setItem('selectedSidebarItem' , this.selectedItem )
  }

  isSelected(item: string): boolean {
    return this.selectedItem === item;
  }


}
