import { createReducer, on } from '@ngrx/store';
import { setSearchText, clearSearchText } from './search.actions';


export interface SearchTextState {
  searchText: string;
 
}

export const initialState:SearchTextState = {
    searchText: ""
  }

export const searchTextReducer = createReducer(
    initialState,
    
    // Handle setting search text
    on(setSearchText, (state, { searchText }) => ({
      ...state,
      searchText // Update searchText in the state
    })),
    
    // Optional: Handle clearing search text
    on(clearSearchText, state => ({
      ...state,
      searchText: '' // Clear the search text
    }))
  );