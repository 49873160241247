import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TermsServicePopupComponent } from './terms-service-popup/terms-service-popup.component';
import { MaterialUiModule } from '../material-ui.modules';
import { ForgetPasswordPopupComponent } from './forget-password-popup/forget-password-popup.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NewLeadPopupComponent } from './new-lead-popup/new-lead-popup.component';
import { LeadSuccessPopupComponent } from './lead-success-popup/lead-success-popup.component';
import { LeadFailurePopupComponent } from './lead-failure-popup/lead-failure-popup.component';
import { AuthorizationFailedPopupComponent } from './authorization-failed-popup/authorization-failed-popup.component';
import { NumberCommaDirective } from '../common/directives/number-comma.directive';
import { TitleCaseDirective } from '../common/directives/title-case.directive';
import { MultiOptionCityPopupComponent } from './multi-option-city-popup/multi-option-city-popup.component';

@NgModule({
  declarations:[
    TermsServicePopupComponent,
    ForgetPasswordPopupComponent ,
    NewLeadPopupComponent,
    LeadSuccessPopupComponent,
    LeadFailurePopupComponent,
    AuthorizationFailedPopupComponent,
    NumberCommaDirective ,
    TitleCaseDirective,
    MultiOptionCityPopupComponent
  ],
  imports: [
    CommonModule ,
  ReactiveFormsModule , 
  FormsModule , 
  MaterialUiModule
],
  exports: [
    TermsServicePopupComponent,
    ForgetPasswordPopupComponent ,
    NewLeadPopupComponent,
    LeadSuccessPopupComponent,
    AuthorizationFailedPopupComponent,
    MultiOptionCityPopupComponent
  ],
})
export class PopupsModule {}
