
<nav class="header-container" >

  <div class="row web-mob">

  <div class="col-md-6  search-section">
      <div class="input-group search-dropdown-menu" *ngIf="showSearchBar">
        <div class="input-group-prepend">
          <div class="btn-group">
            <button type="button" class="btn btn-light dropdown-toggle search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ selectedValueLabel }}
            </button>
            <div class="customize-dropdown-menu" >
            <div class="dropdown-menu">
              <a class="dropdown-item" (click)="selectValue('First Name')">First Name</a>
              <a class="dropdown-item" (click)="selectValue('Last Name')">Last Name</a>
              <a class="dropdown-item" (click)="selectValue('Phone')">Phone</a>
              <a class="dropdown-item" (click)="selectValue('Borrower Email')">Borrower Email</a>
              <a class="dropdown-item" (click)="selectValue('Property City')">Property City</a>
            </div>
          </div>
          </div>
          
        </div>
        <input type="text" (keydown.enter)="performSearch()" [(ngModel)]="searchTerm" class="form-control search-input" placeholder="Search" aria-label="Search">
        <div class="input-group-append clear-btn" *ngIf="searchTerm" (click)="clearSearch()">
          <mat-icon class="mx-4 mt-2 clear-btn">close</mat-icon>
      </div>
        <div class="input-group-append search-container" (click)="performSearch()" >
  
              <img src="assets/icons/search-icon.svg"    style="cursor: pointer;" alt="search-icon" class="mx-2">   
        </div>

    </div>
  </div>
 <div class="col-md-6  lead-section ">
  
    <button type="button" class="btn btn-light add-new-lead-btn" (click)="openNewLeadPopup()"> <img src="assets/icons/plus-icon.svg" alt="plus-icon" class="mx-2"> New Lead</button>


    <div class="logout-section-web">

      <p class="company-name ">{{userData.orgName}}</p>
      <div class="nav-item dropdown mt-2">
          <a class="nav-link dropdown-toggle text-light"  id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  [matMenuTriggerFor]="menu" #menuTrigger>
              <img src="assets/icons/avatar-icon.svg" alt="avtar-icon" class="">  <span class="user-name mx-3" *ngIf="userData">{{ userData.firstName | titlecase}} {{ userData.lastName |titlecase }}</span>
          </a>
         
        </div>
    </div>

    <div class="logout-section-mob">

      <div class="nav-item dropdown  mx-3">
        <a class="nav-link text-light" >
            <img src="assets/icons/avatar-icon.svg" alt="avtar-icon" class="">  
        </a>
      </div>

      
      <div class="name-container " >
        <p class="company-name-mob">{{userData.orgName}}</p>
        <p class="user-name " *ngIf="userData">{{ userData.firstName | titlecase}} {{ userData.lastName |titlecase }}</p>
    </div>
    <p class="mx-3 text-light nav-link dropdown-toggle" [matMenuTriggerFor]="menu" #menuTrigger ></p>

      
    </div>
 </div>
  </div>


  <div class="tab-view">
    
    <div class="row mb-3" *ngIf="showSearchBar">

      <div class="input-group search-dropdown-menu" >
        <div class="input-group-prepend">
          <div class="btn-group">
            <button type="button" class="btn btn-light dropdown-toggle search-dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {{ selectedValueLabel }}
            </button>
            <div class="customize-dropdown-menu" >
            <div class="dropdown-menu">
              <a class="dropdown-item" (click)="selectValue('First Name')">First Name</a>
              <a class="dropdown-item" (click)="selectValue('Last Name')">Last Name</a>
              <a class="dropdown-item" (click)="selectValue('Phone')">Phone</a>
              <a class="dropdown-item" (click)="selectValue('Borrower Email')">Borrower Email</a>
              <a class="dropdown-item" (click)="selectValue('Property City')">Property City</a>
            </div>
          </div>
          </div>
          
        </div>
        <input type="text" (keydown.enter)="performSearch()" [(ngModel)]="searchTerm" class="form-control search-input" placeholder="Search" aria-label="Search">
        <div class="input-group-append clear-btn" *ngIf="searchTerm" (click)="clearSearch()">
          <mat-icon class="mx-4 mt-2 clear-btn">close</mat-icon>
      </div>
        <div class="input-group-append search-container" (click)="performSearch()" >
  
              <img src="assets/icons/search-icon.svg"    style="cursor: pointer;" alt="search-icon" class="mx-2">   
        </div>

    </div>
    </div>

    <div class="d-flex justify-content-between ">
      <button type="button" class="btn btn-light add-new-lead-btn" (click)="openNewLeadPopup()"> <img src="assets/icons/plus-icon.svg" alt="plus-icon" class="mx-2"> New Lead</button>
      <div class="logout-section-web">

        <p class="company-name ">{{userData.orgName}}</p>
        <div class="nav-item dropdown mt-2">
            <a class="nav-link dropdown-toggle text-light"  id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  [matMenuTriggerFor]="menu" #menuTrigger>
                <img src="assets/icons/avatar-icon.svg" alt="avtar-icon" class="">  <span class="user-name mx-3" *ngIf="userData">{{ userData.firstName | titlecase}} {{ userData.lastName |titlecase }}</span>
            </a>
           
          </div>
      </div>
  
    </div>

  </div>

  </nav>


  <div class="logout-menu">

    <mat-menu #menu="matMenu">
      <div class="d-flex m-0 p-0" (click)="logout()">
        <img  src="assets/icons/logout-icon.svg" class="mx-2 logout-icon" />
        <p class="mt-2 mx-1 logout-text">Log Out</p>
      </div>
    </mat-menu>
    
  </div>


