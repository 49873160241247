
<div class="layout-main">

   <div class=" web-view"  *ngIf="!isMobile">
   <div class=" d-flex">
     <div style="width: 6vw;">
       <app-sidebar (toggleMenuSelection)="toggleSelection($event)"></app-sidebar>
     </div>
     <div style="width: 94vw;">
                 
         <app-topbar [showSearchBar]="showSearchBar" (resetSorting)="resetSorting()" (toggleOffcanvas)="openOffcanvas()"></app-topbar>
         <!-- <router-outlet></router-outlet> -->
         <div [ngSwitch]="currentComponent">
          <app-pipeline *ngSwitchCase="'pipeline'"></app-pipeline>
          <app-manage-user  *ngSwitchCase="'manageUsers'"></app-manage-user>
          <div *ngSwitchDefault>Select a component to display</div>
      </div>
          
     </div>

   </div>

   </div>

   <div class="mob-view" *ngIf="isMobile">
    <div class="burger-menu">
      <p class="burger-menu-label mx-3 m-2">Lender Express</p>
      <p class="m-2 mx-3" (click)="toggleBurgerMenu()"> 
        <img [src]="isBurgerMenuOpen ? 'assets/icons/cross-icon.svg' : 'assets/icons/burger-icon.svg'" alt="burger-icon">
      </p>
    </div>
    
    <div *ngIf="isBurgerMenuOpen" class="burger-menu-detail" [ngClass]="{'open': isBurgerMenuOpen}">
      
      
      <div 
      class="d-flex selection-item" 
      [class.selected]="isSelected('pipeline')" 
      (click)="selectItem('pipeline')">
      <img 
          [src]="isSelected('pipeline') ? 'assets/icons/note-icon.svg' : 'assets/icons/note-icon-dark.svg'" 
          alt="pipeline" 
          class="mx-2" 
      />
      <p 
          class="mt-2 mx-2 pipeline-text-mob" 
          [ngStyle]="{ 'color': isSelected('pipeline') ? 'white' : 'black' }"
      >
          Pipeline
      </p>
  </div>
  
  <div 
      *ngIf="checkIsAdmin"
      class="d-flex selection-item" 
      [class.selected]="isSelected('manageUsers')" 
      (click)="selectItem('manageUsers')">
      <img 
          [src]="isSelected('manageUsers') ? 'assets/icons/multi-user-icon.svg' : 'assets/icons/multi-user-dark.svg'" 
          alt="manageUsers" 
          class="mx-2" 
      />
      <p 
          class="mt-2 mx-2 pipeline-text-mob" 
          [ngStyle]="{ 'color': isSelected('manageUsers') ? 'white' : 'black' }"
      >
          Manage Users
      </p>
  </div>
  
    
      <div class="d-flex  selection-item"  >
        <img src="assets/icons/support-icon-dark.svg" alt="support" class="mx-2" />
        <p class="mt-3 mx-2 pipeline-text-mob">Support</p>
      </div>
    </div>
    
    
  
    <app-topbar [showSearchBar]="showSearchBar" (resetSorting)="resetSorting()" (toggleOffcanvas)="openOffcanvas()"></app-topbar>
  
    <div [ngSwitch]="currentComponent">
      <app-pipeline *ngSwitchCase="'pipeline'"></app-pipeline>
      <app-manage-user *ngSwitchCase="'manageUsers'"></app-manage-user>
      <div *ngSwitchDefault>Select a component to display</div>
    </div>
  </div>
  
  

</div>

