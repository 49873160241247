import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { TermsServicePopupService } from '../popups/terms-service-popup/terms-service-popup.service';
import { AuthService } from '../component/auth/auth.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Injectable({
  providedIn: 'root'
})
export class TermsGuard implements CanActivate {

  authorizationFailed = false;
  isAuthenticated = false;
  checkConsent: boolean = false;

  constructor(
    private authService: AuthService,
    private termsService: TermsServicePopupService,
    private router: Router,
    private loader: NgxUiLoaderService
  ) {}

  async canActivate(): Promise<boolean> {
    const token = localStorage.getItem('tokenValue');
    
    // Check if token exists
    if (!token) {
      return false;
    }

    this.loader.start();

    // Wait for the sendToken method to complete
    await this.sendToken(token);

    // Return based on authentication and consent status
    if (this.isAuthenticated && this.checkConsent) {
      return true;
    }

    if (this.authorizationFailed) {
      return false;
    }

    let accepted = false;
    if (this.isAuthenticated && !this.checkConsent) {
      accepted = await this.termsService.openTermsDialog();
    }

    if (accepted) {
      this.updateTermsAgreementConsent({ ipAddress: '192.168.1.1' });
      return true;
    } else {
      return false;
    }
  }

  updateTermsAgreementConsent(payload: any) {
    this.authService.setTermsAgreementConsent(payload).subscribe(
      response => {
        console.log('Terms agreement consent:', response);
      },
      error => {
        console.error('Error fetching terms agreement consent:', error);
      }
    );
  }

  async sendToken(token: any): Promise<void> {
    return new Promise((resolve) => {
      this.authService.sendAuthToken(token).subscribe(
        (response: any) => {
          if (response?.details?.isAuthenticated) {
            this.isAuthenticated = response.details.isAuthenticated;
            this.checkConsent = response.details.userInfo.isConsent;
            localStorage.setItem('userInfo', JSON.stringify(response.details.userInfo));
          } else if (response?.outcomes?.failedValidation) {
            this.authorizationFailed = true;
          }
          this.loader.stop();
          resolve();
        },
        error => {
          console.error('Error sending token:', error);
          localStorage.removeItem('tokenValue');
          this.authorizationFailed = true;
          this.termsService.authorizationFailedDialog();
          this.loader.stop();
          resolve();
        }
      );
    });
  }
}
