<p  class="popup-header-label">Lead Creation Failed</p>
<div  >


    <div class="error-txt">
        <p>{{errorStatus}} </p>
    </div>
   
  <div align="end" class="popup-footer">
    
    <button mat-button class="accept-button " (click)="onClose()" style="color:#FFFFFF;" > CLOSE</button>
    
  </div>

</div>


